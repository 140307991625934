import React, { Component } from 'react'
import logo from './assets/logo.svg'
import Homepage from './components/Homepage'
import Clients from './components/OurClientsPage'
import Contact from './components/ContactPage'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

const RouteVariants = {
  enter: { opacity: 1, delay: 500, beforeChildren: true, x: 20 },
  exit: { opacity: 0.01 },
}

export const AnimatedRoute = ({ children }) => {
  return (
    <motion.div
      className=""
      initial="exit"
      animate="enter"
      exit="exit"
      variants={animations}
      key={children}
    >
      {children}
    </motion.div>
  )
}

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

const animations = {
  exit: { y: '50%', opacity: 0, transition },
  enter: {
    y: '0%',
    opacity: 1,
    transition,
  },
}

class Jet extends Component {
  constructor() {
    super()
    this.state = { location: 'a' }
  }

  updateLocation = (location) => {
    this.setState()
  }

  render() {
    // this is fucking ugly  but it works. Wrap it all in a route so you have
    // access to the location object.
    return (
      <Route
        render={({ location }) => (
          <div className="App">
            <div className="background">
              <header className="header">
                <Link to="/" className="logo">
                  <div>Jet Dynamics</div>
                </Link>
                <div className="header__navigation">
                  <Link
                    to="/"
                    className="header__navigation-link"
                    onClick={() => this.setState({ location: 'Mission' })}
                  >
                    <div className="header__navigation-link">Our mission</div>
                  </Link>
                  <Link
                    to="/clients"
                    className="header__navigation-link"
                    onClick={() => this.setState({ location: 'Clients' })}
                  >
                    <div>Clients</div>
                  </Link>
                  <Link
                    to="/contact"
                    className="header__navigation-link"
                    onClick={() => this.setState({ location: 'Contact' })}
                  >
                    <div className="header__navigation-link contact-button">
                      Contact
                    </div>
                  </Link>
                </div>
              </header>

              <main>
                <div className="main-container">
                  <div className="main-container__line"></div>
                  <AnimatePresence exitBeforeEnter initial={false}>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/" component={Homepage} key="home" />
                        <Route
                          exact
                          path="/clients"
                          component={Clients}
                          key="clients"
                        />
                        <Route
                          exact
                          path="/contact"
                          component={Contact}
                          key="contact"
                        />
                    </Switch>
                  </AnimatePresence>
                </div>
              </main>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Jet
