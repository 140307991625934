import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { AnimatedRoute } from '../Jet'

import ImgContainer from './Homepage-imgs'
import innovation from '../assets/Innovation.png'
import futurism from '../assets/futurism.png'
import technology from '../assets/Technology.png'

let homepageImages = [
  { imgUrl: innovation, description: 'innovation' },
  { imgUrl: futurism, description: 'futurism' },
  { imgUrl: technology, description: 'technology' },
]

let content =
  "Our mission is to use cutting edge technology to solve some of today's toughest problems. Our engineers and scientists have a wide variety of experience from software to hardware spanning across diverse sectors such as government, logistics medical research, and law. We spend our day solving problems that are both difficult and valuable. We are highly interested in the problems that no one else can solve."

export default class Homepage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      homepageImages,
      content,
    }
  }

  renderHomepageImgs = () => {
    return this.state.homepageImages.map((img, i) => {
      return (
        <ImgContainer img={img.imgUrl} description={img.description} key={i} />
      )
    })
  }

  render() {
    return (
      <AnimatedRoute>
        <div className="main-container__wrapper" key="homepage">
          <div className="main-container__page-title">Our Mission</div>
          <div className="main-container__content">
            <div className="main-container__text">{this.state.content}</div>
            <div className="three-wise-men">{this.renderHomepageImgs()}</div>
          </div>
          <div className="body-contact-button">
            <Link to="/contact">
              <div className="contact-button">Contact</div>
            </Link>
          </div>
        </div>
      </AnimatedRoute>
    )
  }
}
