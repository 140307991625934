import React from 'react'
import { Field, reduxForm } from 'redux-form'
import axios from 'axios'
import { AnimatedRoute } from '../Jet'

const submitRequest = async (data) => {
  const response = axios.post('/api/contact', data)
  console.log(response)
}

const SimpleForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <AnimatedRoute>
      <div className="main-container__wrapper">
        <div className="main-container__page-title">Get in touch</div>
        <form
          autoComplete="off"
          spellCheck="false"
          onSubmit={handleSubmit((values) => submitRequest(values))}
        >
          <div className="contact__form-wrapper">
            <div className="contact__form-field">
              <label className="contact__label">Name</label>
              <div>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  className="contact__input-field"
                />
              </div>
            </div>
            <div className="contact__form-field">
              <label className="contact__label">Email</label>
              <div>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  className="contact__input-field"
                />
              </div>
            </div>
            <div>
              <button
                className="contact-button contact__form-button"
                onClick={handleSubmit((values) => submitRequest(values))}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </AnimatedRoute>
  )
}

export default reduxForm({
  form: 'simple', // a unique identifier for this form
})(SimpleForm)
