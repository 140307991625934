import React, { Component } from 'react'


export default class ImgContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div className="a-wise-man">
        <img src={this.props.img} alt="" className="homepage-img"/>
        <div className="homepage__image-descriptor">{this.props.description}</div>
      </div>
    )
  }
}
