import React, { Component } from 'react'
import aussieGossip from '../assets/clients/aussiegossiplogo.png'
import canterbury from '../assets/clients/Canterbury-Bankstown_Primary_RGB_Black_256x255.png'
import ccu from '../assets/clients/CCULogo.png'
import studioMatrix from '../assets/clients/SMlogo.png'
import strathfield from '../assets/clients/STrathlogo.png'
import pitm3d from '../assets/clients/PITM3D.png'
import { AnimatedRoute } from '../Jet'

let clients = [
  { imgUrl: canterbury },
  { imgUrl: ccu },
  { imgUrl: strathfield },
  { imgUrl: aussieGossip },
  { imgUrl: studioMatrix },
  { imgUrl: pitm3d },
]

export default class Clients extends Component {
  constructor() {
    super()
  }

  renderClientImages = () => {
    return clients.map((client) => {
      return <img src={client.imgUrl} alt="" className="client-logos" />
    })
  }

  render() {
    return (
      <AnimatedRoute>
        <div className="main-container__wrapper">
          <div className="main-container__page-title">
            Clients we have worked with
          </div>
          <div className="clients-container">{this.renderClientImages()}</div>
        </div>
      </AnimatedRoute>
    )
  }
}
