import React from 'react';
import ReactDOM from 'react-dom';
// import './styles/index.css';
import './styles/sass/main.scss';


import Jet from './Jet';
import registerServiceWorker from './registerServiceWorker';

import {BrowserRouter} from 'react-router-dom'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import reducers from './reducers'

const createStoreWithMiddleware = applyMiddleware()(createStore);


const renderThis = () => {
  return(
    <Provider store={createStoreWithMiddleware(reducers)}>
      <BrowserRouter>
        <Jet />
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(renderThis(), document.getElementById('root'));
registerServiceWorker();
